import { formatDate } from '../utils/utils';
import { getFullBallast, getProductRelation } from './ballastService';
import { doPost } from './httpHelper';
import { getBranch } from './patientsService';
import moment from 'moment';
import { UserHelper } from '../helpers/UserHelper';
import { OrderEnums } from '../enums/OrderEnums';
export const saveCollectionOrder = async (
  currentUser,
  cnpj,
  patient,
  collection,
  deliveryDate,
  techDetails,
  deliveryTurn
) => {
  const branch = await getBranch(patient.city);
  if (typeof branch === 'string' && branch.indexOf('Branch not found') > 0) {
    return null;
  }
  const turns = [];
  Object.keys(deliveryTurn).forEach((turn) => {
    if (deliveryTurn[turn]) {
      turns.push(turn);
    }
  });
  const obj = {
    request_number_temp: new Date().getTime().toString(),
    commercialization_type: 'P',
    request_type: new OrderEnums().enums.types.RETREAT,
    request_status: 'Solicitado',
    Sap_client: patient.sap_number ?? '',
    //"client": "Nome do Cliente",
    cnpj: cnpj,
    cpf: patient.cpf,
    distribution_center: branch.id,
    destination_sys_cod: '0',
    destination_system: 'SAP',
    date_entry: formatDate(new Date()),
    serial_note: '',
    products: Object.keys(collection).map((prodId) => collection[prodId]),
    net_total_value: 0,
    total_with_tax: 0,
    sta_date: formatDate(new Date()),
    create_in: moment().utc(true).toISOString(),
    create_by: currentUser.email,
    order_type_desc: new OrderEnums().enums.types.RETREAT,
    delivery_forecast: formatDate(deliveryDate),
    delivery_window: turns.join('/'),
    num_carga: '0',
    obs: techDetails ?? '',
  };

  const currentUserJSON = UserHelper.getCurrentUser();
  if (currentUserJSON) {
    if (
      currentUserJSON.isWhiteMartins === '1' ||
      currentUserJSON.isAdmin === '1'
    ) {
      obj.operator_cognito_id = currentUserJSON.username;
    }
  }

  try {
    const resp = await doPost('/requests', obj);

    if (resp.message !== 'Request created') {
      return 'erro interno';
    }

    return obj.request_number_temp;
  } catch (e) {
    return e.toString();
  }
};
const DESCARTAVEL = 1;
export const getCustomerBallast = async (cpf) => {
  const produRelationPromise = getProductRelation(cpf);
  const ballast = await getFullBallast(cpf, '0', 1000);
  const productRelation = await produRelationPromise;
  let accessoriesList = [{ products: [] }];
  if (productRelation.data.length > 0) {
    accessoriesList = productRelation.data;
    const b = accessoriesList
      .filter((x) => x.products.length > 0)
      .map((x) => x.products)
      .flat()
      .map((x) => x.child)
      .flat()
      .map((x) => x.child_product);
    const c = {};
    b.forEach((x) => (typeof c[x] === 'undefined' ? (c[x] = 0) : (c[x] += 1)));
    //const subProducts = await getProductsByCodes(Object.keys(c));
    const mapSubProduct = [];
    Object.keys(c).forEach((s) => {
      /* if (s.product_segment_id === DESCARTAVEL){
                return
            } */
      if (!mapSubProduct.includes(s)) {
        mapSubProduct.push(s);
      }
    });
    /*         const finalAccList = [];
        ballast.data.forEach((dat,i) => {
            dat.products.forEach(prods => {
                if(Object.keys(c).includes(prods.product_id)){
                    if(!finalAccList.includes(prods.product_id)){
                        finalAccList.push(prods.product_id)
                    }
                }
            })
           / dat.products.forEach((prod,j) => {
                if(finalAccList.includes(prod.product_id)){
                    delete ballast.data[i].products[j]
                }
            }) 
        })  */

    accessoriesList.forEach((accList) => {
      accList.products.forEach((p) => {
        p.child.forEach((child, i) => {
          if (
            mapSubProduct.includes(
              child.child_product
            ) /* && finalAccList.includes(child.child_product) */
          ) {
            if (p.full_child_product) {
              p.full_child_product.push({
                id: parseInt(child.child_product),
              });
            } else {
              p.full_child_product = [
                {
                  id: parseInt(child.child_product),
                },
              ];
            }
          }
        });
      });
    });
  }

  const finalList = ballast.data
    .map((x) => x.products)
    .flat()
    .map((prod) => ({
      id: prod.product_id,
      name: prod.product_description,
      accessories: accessoriesList
        .map((accList) =>
          accList.products
            .filter(
              (x) =>
                parseInt(prod.product_id) === parseInt(x.parent_product) &&
                x.full_child_product
            )
            .map((x) => x.full_child_product)
        )
        .flat(),
      segment: prod.segment?.description,
      category: prod.category?.description,
      subCategory: prod.subcategory?.description,
      serialNumber: prod.manufacturer_ns,
      images: [],
      manuals: [],
      qty: prod.total_balance,
    }))
    .filter(
      (x) =>
        x.segment && x.segment.toLowerCase() !== 'Descartável'.toLowerCase()
    );
  const mapP = {};
  finalList.forEach((ballast) => {
    if (!mapP[ballast.id]) {
      mapP[ballast.id] = ballast;
    }
  });
  return Object.values(mapP);
};
