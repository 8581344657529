import { Table } from '../base/table';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import React from 'react';
import moment from 'moment';
import { OrderTableData } from '../types/Order';
import { OrderEnums } from '../enums/OrderEnums';
import { AlarmEnum } from '../enums/AlarmEnum';
import { getProofOfDelivery } from '../services/ordersService';

const orderEnums = new OrderEnums();

export const delay = (ms: any) => {
  return new Promise((res) => {
    setTimeout(res, ms);
  });
};

export const splitString = (word: any) => {
  const parts = word.split(' ');
  const finalList = [];
  finalList.push(parts[0]);
  for (let i = 1; i < parts.length; i++) {
    if (parts[i].length < 3) {
      finalList[finalList.length - 1] =
        finalList[finalList.length - 1] + ' ' + parts[i];
    } else {
      finalList.push(parts[i]);
    }
  }
  return finalList;
};

export const formatDate = (date: Date | string, format?: string) => {
  if (!date) return '';

  return moment(date).format(format ?? 'DD/MM/YYYY');
};

/**
 * Get date on string format dd/mm/yyyy 00:00:00 and transform into ISO string.
 * @param dateString date string dd/mm/yyyy 00:00:00
 * @returns ISO date string yyyy-mm-ddT00:00:00
 */
export const formatDateToISO = (dateString: string) => {
  if (!dateString) return '';

  const dateStringArray = dateString.split(' ');

  if (dateStringArray.length === 2) {
    const date = dateStringArray[0].split('/').reverse().join('-');
    const time = dateStringArray[1];

    return date + 'T' + time;
  } else if (dateStringArray.length === 1) {
    const date = dateStringArray[0].split('/').reverse().join('-');
    return date + 'T00:00:00';
  }

  return 'Invalid Date';
};

export const formatDateYYYYMMDD = (date: any) => {
  if (!date) return '';
  const month = date.getMonth() + 1;
  return `${date.getFullYear()}-${month < 10 ? '0' + month : month}-${
    date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  }`;
};

export const formatDateMMddYYYY = (date: any) => {
  if (!date) {
    return '';
  }
  const month = date.getMonth() + 1;
  return `${month < 10 ? '0' + month : month}/${
    date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  }/${date.getFullYear()}`;
};

export const getBase64Img = (x: any) => {
  return `data:image/png;base64,${x}`;
};

const replaceAll = (str: any, a: any, b: any) => {
  return str.split(a).join(b);
};
export const unformatCPF = (cpf: any) => {
  return replaceAll(replaceAll(cpf, '.', ''), '-', '');
};

export const formatCPF = (cpf: any) => {
  if (!cpf) return cpf;
  let formated = '';
  for (let i = 0; i < cpf.length; i++) {
    formated += cpf[i];
    if (i === 2 || i === 5) {
      formated += '.';
    }
    if (i === 8) {
      formated += '-';
    }
  }
  return formated;
};

export const getLast12Months = (date: any, period = 12) => {
  const dates = [];
  while (period >= 0) {
    let month = date.getMonth();
    const year = date.getFullYear();
    dates.push({ month, year });
    month--;
    date.setMonth(month);
    period--;
  }
  //dates.shift();
  return dates;
};
const monthMap = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];
export const formatMonthYear = (month: any, year: any) => {
  return `${monthMap[month]} - ${year}`;
};

export const getDeliveryLabel = (
  order: OrderTableData,
  onOpenActivity: any
) => {
  if (orderEnums.status.isConcluded(order.status.id.toString())) {
    return (
      <div style={{ display: 'flex', justifyContent: 'start' }}>
        {AlarmEnum.color.isWhite(order.alarm) &&
          order.cec_length !== 0 &&
          'Comprovante: '}
        {AlarmEnum.color.isWhite(order.alarm) && order.cec_length !== 0 &&
          <HiOutlineDocumentReport
            key={order.id}
            style={{ fontSize: '18px', cursor: 'pointer' }}
            onClick={() => onOpenActivity(order, order.sap_numbers)}
          ></HiOutlineDocumentReport>}
      </div>
    );
  } else if (orderEnums.status.isUnsuccessful(order.status.id.toString())) {
    return <></>;
  } else {
    let turns_message = '';
    const turnos = [];
    const deliveryTurn = order.delivery_window.split('/');

    if (deliveryTurn.includes('manha')) {
      turnos.push('manhã');
    }
    if (deliveryTurn.includes('tarde')) {
      turnos.push('tarde');
    }
    if (deliveryTurn.includes('noite')) {
      turnos.push('noite');
    }
    if (turnos.length === 3) {
      turns_message = ' ';
    } else if (turnos.length > 0) {
      turns_message += ' no período da ' + turnos.join(' ou ');
    }

    if (order.type === 'Implantação') {
      return `Estimativa de entrega: ${order.delivery_forecast}${turns_message}`;
    }

    if (order.type === 'Recolhimento') {
      return `Estimativa de recolhimento em: ${order.delivery_forecast}${turns_message}`;
    }

    if (order.type === 'Recarga') {
      return `Estimativa de recarga em: ${order.delivery_forecast}${turns_message}`;
    }

    if (order.type === 'Assistência Técnica') {
      return `Agendamento para: ${order.delivery_forecast}${turns_message}`;
    }

    if (order.type === 'Venda') {
      return `${order.delivery_forecast}${turns_message}`;
    }

    if (order.type === 'Serviço') {
      return `${order.delivery_forecast}${turns_message}`;
    }

    return '';
  }
};

export const cognitoStatusMapping = (status: any) => {
  switch (status) {
    case 'CONFIRMED':
      return 'Confirmado';
    case 'FORCE_CHANGE_PASSWORD':
      return 'Mudança de senha forçada';
    default:
      return status;
  }
};

export const getPdfDownloadUrl = (code: any, name: any) => {
  // eslint-disable-next-line no-undef
  return `${process.env.REACT_APP_PDF_DOWNLOAD_URL}?code=${code}&name=${name}`;
};

export async function downloadPDF(endpointURL: any) {
  try {
    const response = await fetch(endpointURL);
    const base64Str = await response.text();
    const search = new URLSearchParams(endpointURL);
    const binaryString = atob(base64Str);

    const binaryArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      binaryArray[i] = binaryString.charCodeAt(i);
    }

    const binaryBlob = new Blob([binaryArray], { type: 'application/pdf' });
    const url = URL.createObjectURL(binaryBlob);

    const link: any = document.createElement('a');
    link.href = url;
    link.download = search.get('name');
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.log('Erro ao fazer o download do PDF:', error);
  }
}

export const getLastFromSplit = (str: any, sep: any) => {
  const parts = str.split(sep);
  return parts[parts.length - 1];
};

export const parseObs = (obs: any, mapProd: any) => {
  try {
    const obj = JSON.parse(obs);
    return (
      <div>
        <p style={{ padding: '0px', margin: '2px' }}>
          {Object.keys(obj.terms).length > 0 ||
          Object.keys(obj.Search).length > 0 ? (
            <React.Fragment>
              <b>Detalhes da ocorrência:</b> {obj.obs}
            </React.Fragment>
          ) : (
            obj.obs
          )}
        </p>
        {Object.keys(obj.terms).length > 0 ? (
          <p>
            <b>Termos buscados:</b>{' '}
            {Object.keys(obj.terms).map((x, i) => (
              <i key={i}>{obj.terms[x].join(', ')}</i>
            ))}
          </p>
        ) : (
          <></>
        )}
        {Object.keys(obj.Search).length > 0 ? (
          Object.keys(obj.Search).map((eqId, i) => (
            <Table
              key={i}
              header={['Problema', 'Causa', 'Solução']}
              data={(obj.Search[eqId] ?? [])
                .map((x: any) => {
                  const c = { ...x };
                  delete c.genId;
                  return c;
                })
                .flat()}
              height={null}
              expandable={null}
              onRowSelection={null}
            ></Table>
          ))
        ) : (
          <></>
        )}
      </div>
    );
  } catch (e) {
    return obs;
  }
};

// TODO: Move to helper
export const genericOrderCode = '85000378';
export const isGenericOrder = (code: string) => {
  return code === genericOrderCode;
};

// TODO: Move to helper
export const genericCylinderCode = '80000017';
export const isGenericCylinder = (cylinder: any) => {
  return (
    cylinder?.id === genericCylinderCode ||
    cylinder?.code === genericCylinderCode
  );
};
