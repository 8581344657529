import React from 'react';
import { FormControl, SxProps, Theme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StyledDatePicker } from './styles';

type DatePickerProps = {
  label?: string;
  value?: moment.Moment;
  handleChange: (date?: moment.Moment) => void;
  sx?: SxProps<Theme>;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  value,
  handleChange,
  sx,
}) => {
  return (
    <FormControl fullWidth size="small" sx={sx}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StyledDatePicker
          label={label}
          value={value?.utc(false).startOf('day')}
          onChange={(date) => handleChange(date ? date.utc(true).startOf('day') : undefined)}
          slotProps={{ textField: { size: 'small' } }}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>
    </FormControl>
  );
};
